const supportEmail = "vr@mhssz.hu";

export default {
    hu: {
        "calendar": "Versenynaptár",
        "climbing-type": "Versenyszám",
        "date": "Dátum",
        "category": "Besorolás",
        "events": "Események",
        "add": "Esemény hozzáadása",
        "add-group": "Csoport hozzáadaása",
        "edit": "Esemény szerkesztése",
        "remove": "Esemény törlése",
        "event": "Esemény",
        "name": "Teljes név",
        "label": "Rövid név",
        "registration": "Előnevezés",
        "registrationPhase1": "Kezdete sportorvosival",
        "registrationPhase2": "Kezdete sportorvosi nélkül",
        "registrationPhase3": "Rajtcsomag határidő",
        "registrationEnd": "Vége",
        "description": "Leírás",
        "type": "Típus",
        "BOULDER": "boulder",
        "BOULDER-short": "B",
        "LEAD": "nehézségi",
        "LEAD-short": "N",
        "SPEED": "gyorsasági",
        "SPEED-short": "GY",
        "OTHER": "hegy- és sziklamászó fesztivál", // TODO fix :D
        "OTHER-short": "",
        "AMATEUR": "amatőr",
        "PROFESSIONAL": "profi",
        "PREPARATION": "előkészítés",
        "ACTIVE": "aktív",
        "CLOSED": "lezárt",
        "status": "Státusz",
        "startDate": "Kezdete",
        "endDate": "Vége",
        "location": "Helyszín",
        "location.title": "Mikor-hol",
        "base-data": "Alapadatok",
        "groups": "Csoportok",
        "registrations": "Nevezések",
        "S_MINI" : "szupermini",
        "MINI": "mini",
        "CHILD": "gyerek",
        "TEEN_AGE": "serdülő",
        "YOUTH_A": "ifjúsági A",
        "YOUTH_B": "ifjúsági B",
        "JUNIOR": "junior",
        "ADULT": "felnőtt",
        "SENIOR": "senior",
        "slot": "hely",
        "free-slots": "szabad",
        "full": "betelt",
        "login-for-registration": "Az előnevezéshez be kell lépned, ha még nincs fiókod, akkor először regisztrálnod kell. ",
        "login": "Belépés",
        "account-add": "Regisztráció",
        "cancel": "Lemondás",
        "group-change": "Csoportváltás",
        "registration-success": "Sikeres előnevezés",
        "change-success": "Sikeres módosítás",
        "cancel-success": "Sikeres lemondás",
        "registration-error": "Nem sikerült előnevezni, kérlek írj a " + supportEmail + " címre.",
        "change-error": "Nem sikerült a művelet, kérlek írj a " + supportEmail + " címre.",
        "modifiedAt": "módosítva",
        "participants": "Résztvevők",
        "participants-explanation": "A fiókod regisztrációjakor, illetve később a Fiók oldalon (belépés után), be tudod állítani, hogy megjelenjen-e a neved ebben a a listában."
    },
    en: {
        "calendar": "Calendar",
        "climbing-type": "Discipline",
        "date": "Date",
        "category": "Category",
        "events": "Events",
        "add": "Add new event",
        "add-group": "Add new group",
        "edit": "Edit event",
        "remove": "Remove event",
        "event": "Event",
        "name": "Full name",
        "label": "Short name",
        "registration": "Registration",
        "registrationPhase1": "Start time with registered medical exam",
        "registrationPhase2": "Start time with no medical exam registered",
        "registrationPhase3": "Start package deadline",
        "registrationEnd": "Registration deadline",
        "description": "Description",
        "type": "Type",
        "BOULDER": "boulder",
        "BOULDER-short": "B",
        "LEAD": "Lead",
        "LEAD-short": "L",
        "SPEED": "Speed",
        "SPEED-short": "S",
        "OTHER": "Rock climbing festival", // TODO fix :D
        "OTHER-short": "",
        "AMATEUR": "amateur",
        "PROFESSIONAL": "professional",
        "PREPARATION": "preparation",
        "ACTIVE": "active",
        "CLOSED": "closed",
        "status": "Status",
        "startDate": "Start date",
        "endDate": "End date",
        "location": "Location",
        "location.title": "When and where",
        "base-data": "Base data",
        "groups": "Groups",
        "registrations": "Registrations",
        "S_MINI" : "supermini",
        "MINI": "mini",
        "CHILD": "child",
        "TEEN_AGE": "teen age",
        "YOUTH_A": "Youth A",
        "YOUTH_B": "Youth B",
        "JUNIOR": "junior",
        "ADULT": "adult",
        "SENIOR": "senior",
        "slot": "slot",
        "free-slots": "free slots",
        "full": "full",
        "login-for-registration": "In order to register to an event you must sign in. If you do not have an account, yet, you must register in the system, first.",
        "login": "Login",
        "account-add": "Register",
        "cancel": "Cancel registration",
        "group-change": "Change starting group",
        "registration-success": "Successful registration",
        "change-success": "Change is successful",
        "cancel-success": "Successful cancelation",
        "registration-error": "You were unable to register to the event. Please write an e-mail to " + supportEmail + ".",
        "change-error": "Operation was not completed. Please write an e-mail to " + supportEmail + ".",
        "modifiedAt": "modified at",
        "participants": "Participants",
        "participants-explanation": "You have set whether your name appears in this public list of participants when you have registered in the system. You may change that setting on the Account panel after successfully logging in."
    }
};