import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Card from "../../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../../Lib/material-dashboard/components/Card/CardBody";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import {successColor} from "../../../Lib/material-kit/assets/jss/material-kit-pro-react";
import Grid from "@material-ui/core/Grid";
import Button from "../../../Lib/material-kit/components/CustomButtons/Button";
import {ageGroups} from "../../../Data/agegroups";
import {useChampionshipResults} from "../../../Api/championships";
import {grayColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) =>({
      card: {
        margin: "8px 0",
        paddingBottom: "8px"
      },
      title: {
        marginBottom: 10,
        color: successColor[0],
        // fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      button: {
        padding: "8px 16px",
        backgroundColor: successColor[0]
      },
      name : {
        [theme.breakpoints.up("md")]: {
          minWidth: "20em"
        }
      },
      partition: {
        border: 0,
        borderCollapse: "collapse",
        cellSpacing: 0,
        cellPadding: 0,
        "& th": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          backgroundColor: successColor[5],
          color: grayColor[2],
          fontSize: "0.75rem",
          fontWeight: 400
        },
        "& tr": {
          // "&:nth-child(odd)": {
          //   backgroundColor: grayColor[10]
          // }
          borderBottom: "1px solid " + grayColor[10]
        },
        "& td": {
          padding: "4px 8px 4px 8px",
        }
      },
      right: {
        textAlign: "right"
      }
    })
);

const ageGroupOrdinals = {
  'ADULT': 0,
  'S_MINI' : 1,
  'MINI': 2,
  'CHILD': 3,
  'TEEN_AGE': 4,
  'YOUTH_B': 5,
  'YOUTH_A': 6,
  'JUNIOR': 7,
  'SENIOR': 8
};

const compare = (a, b) => {
  const [a_gender, a_ageGroup] = a.split('/');
  const [b_gender, b_ageGroup] = b.split('/');

  if (a_ageGroup !== b_ageGroup) return ageGroupOrdinals[a_ageGroup] - ageGroupOrdinals[b_ageGroup];
  if (a_gender !== b_gender) return (a_gender === 'FEMALE' && b_gender === 'MALE') ? -1 : 1;

  return 0;
};

export default ({id}) => {
  debug("RENDER", "Championship.Details", () => [id]);

  const {t} = useTranslation('championships');
  const classes = useStyles();

  const {loadingStatus, data} = useChampionshipResults(id);

  const [genderFilter, setGenderFilter] = useState(['MALE', 'FEMALE']);
  const [ageGroupFilter, setAgeGroupFilter] = useState(ageGroups);

  // using memo to avoid re-calculating when user filters

  const [competitions, labels, partitions, keys] = useMemo(() => {
    if (loadingStatus) return [[], [], {}];

    const competitions = [];
    const labels = [];
    const partitions = {};

    // push it into the array, so detail render will know the order and all competitions
    data.competitions.forEach(r => {
      competitions.push(r.id.toString());
      labels.push(r.mnemonic);
    });

    data.results.forEach(r => {
      const key = r.gender + "/" + r.ageGroup;

      let partition = partitions[key];
      if (partition === undefined) partitions[key] = partition = [];

      partition.push(r);
    });

    const keys = Object.keys(partitions);
    keys.sort(compare);

    keys.forEach(key => {
      const p = partitions[key];
      if (p.length === 0) return;

      p.sort((a, b) => {
        if (a.totalPoints !== b.totalPoints) return b.totalPoints - a.totalPoints;
        return a.name.localeCompare(b.name);
      });

      let position = 1;
      let previous = p[0].totalPoints;
      let same = 0;

      p.forEach(r => {
        if (r.totalPoints !== previous) {
          position += same;
          r.position = position;
          same = 1;
        } else {
          r.position = position;
          same += 1;
        }
        previous = r.totalPoints
      })
    });

    return [competitions, labels, partitions, keys];
  }, [loadingStatus, data]);

  if (loadingStatus) return null;

  const path = [
    {label: t('results'), url: "/championships"},
    {label: data.name + " - " + data.year + " - " + t(data.climbingType), url: "/championships/" + data.id + "/details"}
  ];

  return (
      <ContentBox path={path}>
        <div>
          <Button className={classes.button} onClick={() => setGenderFilter(['MALE', 'FEMALE'])}>{t('all')}</Button>
          <Button className={classes.button} onClick={() => setGenderFilter(['FEMALE'])}>{t('FEMALE')}</Button>
          <Button className={classes.button} onClick={() => setGenderFilter(['MALE'])}>{t('MALE')}</Button>
        </div>
        <div>
          <Button className={classes.button} onClick={() => setAgeGroupFilter(ageGroups)}>{t('all')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("ADULT")}>{t('open')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("S_MINI")}>{t('S_MINI')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("MINI")}>{t('MINI')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("CHILD")}>{t('CHILD')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("TEEN_AGE")}>{t('TEEN_AGE')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("YOUTH_A")}>{t('YOUTH_A')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("YOUTH_B")}>{t('YOUTH_B')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("JUNIOR")}>{t('JUNIOR')}</Button>
          <Button className={classes.button} onClick={() => setAgeGroupFilter("SENIOR")}>{t('SENIOR')}</Button>
        </div>
        <Grid container>
          {keys.map(k => {
            const [gender, ageGroup] = k.split('/');
            if (genderFilter.indexOf(gender) === -1) return null;
            if (ageGroupFilter.indexOf(ageGroup) === -1) return null;
            return <Partition
                key={k}
                gender={gender}
                ageGroup={ageGroup}
                data={partitions[k]}
                competitions={competitions}
                labels={labels}
                t={t}
                classes={classes}/>
          })}
        </Grid>
      </ContentBox>
  )
};

const Partition = ({ageGroup, gender, t, classes, data, competitions, labels}) => {

  let genderLabel;
  switch (ageGroup) {
    case "JUNIOR":
    case "ADULT":
    case "SENIOR":
      genderLabel = t(gender);
      break;
    default :
      genderLabel = t(gender === 'FEMALE' ? 'GIRL' : 'BOY')
  }

  return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardBody>
            <Box className={classes.title}>{t(ageGroup)} {genderLabel}</Box>
            <table className={classes.partition}>
              <thead>
              <tr>
                <th>#</th>
                <th></th>
                {labels.map(l =>
                    <th key={l}>{l}</th>
                )}
                <th>{t('total')}</th>
              </tr>
              </thead>
              <tbody>
              {data.map(r => <tr key={r.name}>
                <td><b>{r.position}</b></td>
                <td className={classes.name}>{r.name}</td>
                {competitions.map(id =>
                    <td key={id} className={classes.right}>{r.points[id] || '-'}</td>
                )}
                <td className={classes.right}><b>{r.totalPoints}</b></td>
              </tr>)}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Grid>);
}

