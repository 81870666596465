import React, {useState} from "react";
import {useCompetitionResults} from "../../../Api/competitions";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Card from "../../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../../Lib/material-dashboard/components/Card/CardBody";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Button from "../../../Lib/material-kit/components/CustomButtons/Button";
import {ageGroups} from "../../../Data/agegroups";
import {grayColor, infoColor, successColor} from "../../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react";

const rankNames = [
  "QUALIFIER",
  "QUATERFINAL",
  "SEMIFINAL",
  "FINAL",
  "SUPERFINAL"
];


const useStyles = makeStyles((theme) => ({
      card: {
        margin: "8px 0",
        paddingBottom: "8px"
      },
      title: {
        marginBottom: 10,
        color: successColor[4],
        // fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
      },
      activeButton: {
        padding: "8px 16px",
        backgroundColor: successColor[0]
      },
      inactiveButton: {
        padding: "8px 16px",
        color: grayColor[2],
        backgroundColor: successColor[5]
      },
      name: {
        [theme.breakpoints.up("md")]: {
          minWidth: "20em"
        }
      },
      position: {
        fontWeight: 500,
        fontSize: 14,
        color: "teal",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
          minWidth: "2em"
        }
      },
      partition: {
        border: 0,
        borderCollapse: "collapse",
        cellSpacing: 0,
        cellPadding: 0,
        "& th": {
          padding: "4px 8px 4px 8px",
          textAlign: "center",
          textTransform: "lowercase",
          backgroundColor: successColor[5],
          color: grayColor[2],
          fontSize: "0.75rem",
          fontWeight: 400
        },
        "& tr": {
          //           // "&:nth-child(odd)": {
          //           //   backgroundColor: grayColor[13]
          //           // }
          borderBottom: "1px solid " + grayColor[10]
        },
        "& td": {
          padding: "4px 8px 4px 8px",
        }
      },
      right: {
        textAlign: "right"
      }
    })
);

const ageGroupOrdinals = {
  'ADULT': 0,
  'S_MINI' : 1,
  'MINI': 2,
  'CHILD': 3,
  'TEEN_AGE': 4,
  'YOUTH_B': 5,
  'YOUTH_A': 6,
  'JUNIOR': 7,
  'SENIOR': 8
};

const compare = (a, b) => {
  if (a.ageGroup !== b.ageGroup) return ageGroupOrdinals[a.ageGroup] - ageGroupOrdinals[b.ageGroup];
  if (a.gender !== b.gender) return (a.gender === 'FEMALE' && b.gender === 'MALE') ? -1 : 1;
  return 0;
};

export default ({id, path}) => {
  debug("RENDER", "Competitions.Details", () => [id]);

  const {t} = useTranslation('competition');
  const classes = useStyles();

  const {loadingStatus, data} = useCompetitionResults(id);

  let initial = sessionStorage.getItem("competition-details-filter");
  if (initial) {
    initial = JSON.parse(initial);
  } else {
    initial = {genderFilter: ['MALE', 'FEMALE'], ageGroupFilter: ageGroups};
  }

  const [genderFilter, setGenderFilter] = useState(initial.genderFilter);
  const [ageGroupFilter, setAgeGroupFilter] = useState(initial.ageGroupFilter);

  sessionStorage.setItem('competition-details-filter', JSON.stringify({genderFilter, ageGroupFilter}));

  if (loadingStatus) return null;

  const p = path || [
    {label: t('results'), url: "/championships"},
    {label: data.name, url: "/competitions/" + data.id + "/details"}
  ];

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const genderClass = (expected) => {
    return arraysEqual(genderFilter, expected) ? classes.activeButton : classes.inactiveButton;
  };

  const ageGroupClass = (expected) => {
    return arraysEqual(ageGroupFilter, expected) ? classes.activeButton : classes.inactiveButton;
  };

  data.partitions.sort(compare);

  return (
      <ContentBox path={p}>
        <div>
          <Button className={genderClass(['MALE', 'FEMALE'])} onClick={() => setGenderFilter(['MALE', 'FEMALE'])}>{t('all')}</Button>
          <Button className={genderClass(['FEMALE'])} onClick={() => setGenderFilter(['FEMALE'])}>{t('FEMALE')}</Button>
          <Button className={genderClass(['MALE'])} onClick={() => setGenderFilter(['MALE'])}>{t('MALE')}</Button>
        </div>
        <div>
          <Button className={ageGroupClass(ageGroups)} onClick={() => setAgeGroupFilter(ageGroups)}>{t('all')}</Button>
          <Button className={ageGroupClass(["ADULT"])} onClick={() => setAgeGroupFilter(["ADULT"])}>{t('ADULT')}</Button>
          <Button className={ageGroupClass(["S_MINI"])} onClick={() => setAgeGroupFilter(["S_MINI"])}>{t('S_MINI')}</Button>
          <Button className={ageGroupClass(["MINI"])} onClick={() => setAgeGroupFilter(["MINI"])}>{t('MINI')}</Button>
          <Button className={ageGroupClass(["CHILD"])} onClick={() => setAgeGroupFilter(["CHILD"])}>{t('CHILD')}</Button>
          <Button className={ageGroupClass(["TEEN_AGE"])} onClick={() => setAgeGroupFilter(["TEEN_AGE"])}>{t('TEEN_AGE')}</Button>
          <Button className={ageGroupClass(["YOUTH_B"])} onClick={() => setAgeGroupFilter(["YOUTH_B"])}>{t('YOUTH_B')}</Button>
          <Button className={ageGroupClass(["YOUTH_A"])} onClick={() => setAgeGroupFilter(["YOUTH_A"])}>{t('YOUTH_A')}</Button>
          <Button className={ageGroupClass(["JUNIOR"])} onClick={() => setAgeGroupFilter(["JUNIOR"])}>{t('JUNIOR')}</Button>
          <Button className={ageGroupClass(["SENIOR"])} onClick={() => setAgeGroupFilter(["SENIOR"])}>{t('SENIOR')}</Button>
        </div>
        <Grid container>
          {data.partitions.map(p => {
            if (genderFilter.indexOf(p.gender) === -1) return null;
            if (ageGroupFilter.indexOf(p.ageGroup) === -1) return null;
            if (p.ageGroup !== 'ADULT' && p.tags.indexOf('amateur') !== -1) return null;
            if (p.results.length === 0) return null;
            return <Partition key={p.gender + p.ageGroup + p.tags} data={data} partition={p} t={t} classes={classes}/>
          })}
        </Grid>
      </ContentBox>
  )
};

const skip = (results) => {
  for (let i = 0; i < results.sectionResults.length; i++) {
    const sr = results.sectionResults[i];
    for (let j = 0; j < sr.routeResults.length; j++) {
      if (sr.routeResults[j].bestHoldNumber !== 0) {
        return false;
      }
    }
  }
  return true;
};

const Partition = ({data, partition, t, classes}) => {

  let genderLabel;
  switch (partition.ageGroup) {
    case "JUNIOR":
    case "ADULT":
    case "SENIOR":
      genderLabel = t(partition.gender);
      break;
    default :
      genderLabel = t(partition.gender === 'FEMALE' ? 'GIRL' : 'BOY')
  }

  return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardBody>
            <Box className={classes.title}>{t(partition.ageGroup)} {genderLabel} {partition.tags === "" ? "" : " - " + t(partition.tags)}</Box>
            <table className={classes.partition}>
              <thead>
              <tr>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}/>
                {partition.sections.map(sid =>
                    partition.climbingType === 'BOULDER'
                        ? <BoulderHeader data={data} sid={sid} top t={t}/>
                        : <LeadHeader data={data} sid={sid} top t={t}/>
                )}
              </tr>
              <tr>
                {partition.sections.map(sid =>
                    partition.climbingType === 'BOULDER'
                        ? <BoulderHeader data={data} sid={sid} t={t}/>
                        : <LeadHeader data={data} sid={sid} t={t}/>
                )}
              </tr>
              </thead>
              <tbody>
              {partition.results.map(r =>
                  skip(r) ? null :
                      <tr key={r.startNumber}>
                        <td className={classes.position}>{r.position}</td>
                        <td className={classes.name}>{r.name}</td>
                        {partition.climbingType === 'BOULDER'
                            ? r.sectionResults.map(sr => <BoulderResults key={sr.id} sr={sr} sections={partition.sections}/>)
                            : r.sectionResults.map(sr => <LeadResults key={sr.id} sr={sr} sections={partition.sections} classes={classes}/>)
                        }
                      </tr>)}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Grid>);
};


const BoulderHeader = ({data, sid, top, t}) => {
  if (top) return <th key={sid} colSpan={4}>{t(rankNames[data.sections[sid].rank])}</th>;

  return <React.Fragment>
    <th>{t('tops')}</th>
    <th>{t('zones')}</th>
    <th>{t('topTries')}</th>
    <th>{t('zoneTries')}</th>
  </React.Fragment>
};

const LeadHeader = ({data, sid, top, t}) => {
  if (top) return <th key={sid} colSpan={data.sections[sid].routes.length + 1}>{t(rankNames[data.sections[sid].rank])}</th>;

  return <React.Fragment>
    <th>{t('points')}</th>
    {data.sections[sid].routes.map(r => <th key={r.position}>{r.label}</th>)}
  </React.Fragment>
};

const BoulderResults = ({sr, sections}) => {
  if (sections.indexOf(sr.section) === -1) return null;
  return <React.Fragment>
    <td>{sr.tops}</td>
    <td>{sr.zones}</td>
    <td>{sr.topTries}</td>
    <td>{sr.zoneTries}</td>
  </React.Fragment>
};

const LeadResults = ({sr, classes, sections}) => {
  if (sections.indexOf(sr.section) === -1) return null;

  return <React.Fragment>
    <td className={classes.right}>{sr.points.toFixed(2)}</td>
    {sr.routeResults.map(rr => <td>{rr.bestHoldNumber / 10}</td>)}
  </React.Fragment>
}

